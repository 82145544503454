const banner = {
  title: '网站认证解决方案',
  btn: '立即咨询',
  summary: '通过第三方网站身份验证服务对域名、网站、工商登记或组织机构信息进行严格交互审核来验证网站真实身份'
}
const cjnModules = [
  {
    title: '核心产品',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '安全联盟',
        content: '安全联盟验证是由"安全联盟可信验证服务中心"以细分行业的从业资格为基准，推出的网站主办方实体身份信息验证服务，确保被验证网站的实体身份权威真实。网站通过验证后，可被收录至安全联盟信誉档案库，并获得权威验证标识及信誉档案证明，可接受全国用户公开查询，有效提升网站信誉。'
      },
      {
        icon: '/images/content/key.svg',
        title: '可信网站',
        content: '"可信网站"认证，是由中网推出的第三方网站真实身份验证服务，它通过对域名、网站、工商登记或组织机构信息进行严格交互审核来验证网站真实身份，此外，还可根据网站需要开通"网站运行监护、网页篡改监护、木马病毒监控"等网站安全服务。对网站而言，"可信网站"验证能帮助网站健全网络钓鱼防范措施，拓宽网站可信品牌的展示和推广力度；对网民而言，"可信网站"验证能有效提高亿万网民辨别网站真伪和识破网络诈骗的能力。'
      },
      {
        icon: '/images/content/key.svg',
        title: '诚信网站',
        content: '诚信网站认证是中国电子商务协会批准中国电子商务协会数字服务中心在全国负责具体实施的政府权威认证。中国电子商务协会是由工信部申请，经国务院批准国家民政部核准登记注册的全国性社团组织，其业务活动受工信部的指导和国家民政部的监督管理。中国电子商务协会工作开展长期得到全国人大常委会、工信部、商务部、发改委、教育部、科技部、海关总署、邮政总局等国家部委的直接指导和全力支持。中国电子商务协会数字服务中心是中国电子商务协会重要职能机构。'
      },
      {
        icon: '/images/content/key.svg',
        title: '认证联盟',
        content: '水滴信用® 可信网站认证是上海凭安征信服务有限公司面向企业推出的网站认证服务，在对网站的域名、主办单位、运营资质和网站安全等信息核验后，向合格的网站颁发认证证书，并通过各大互联网终端向网民展示认证信息，有效提升网站的可信形象，助力网站发展。'
      }
    ]
  },
  {
    title: '网站认证的六大核心价值',
    span: 6, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '国家级权威认证',
        content: '权威机构评定，非第三方企业评级，体现权威，公正，彰显身份，赢得信任。'
      },
      {
        icon: '/images/content/key.svg',
        title: '展现企业真实身份',
        content: '官方权威数据库收录，全网数据共享，企业真实身份供亿万网名随时查验彰显企业实力和真实可靠性。'
      },
      {
        icon: '/images/content/key.svg',
        title: '助力网络营销',
        content: '在线获取意向客户信任，助力中小企业网站解决诚信难题减少评估周期，快人一步赢得商机。'
      },
      {
        icon: '/images/content/key.svg',
        title: '防范恶意假冒网站',
        content: '助网站防范钓鱼假冒网站，树立网站形象，助网民便捷检验网站真实身份，防范欺诈被骗。'
      },
      {
        icon: '/images/content/key.svg',
        title: '网站监护',
        content: '网站安全监测全面对接，对网站安全24小时实时监护，保障企业网站处于正常状态，安全运行，免受攻击.'
      },
      {
        icon: '/images/content/key.svg',
        title: '全方位防伪技术',
        content: '独具防拷贝、加密技术、时间戳、防伪链等全方位防伪技术更有效的保护企业网站的互联网权益。'
      }
    ]
  },
  {
    title: '网站认证为什么选择我们？',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '多年专注认证行业，精心专业',
        content: '汇智云安自成立以来，一直专注认证行业，经验丰富，为您提供有效的认证方案'
      },
      {
        icon: '/images/content/key.svg',
        title: '认证绿色通道，当天通过',
        content: '汇智云安得到相关认证部门支持，拥有认证绿色通道，比一般同行高效3-5倍'
      },
      {
        icon: '/images/content/key.svg',
        title: '专业认证团队，提供优质服务',
        content: '认证专员一对一服务，收到资料，交由提单部门提交，形成流水线工作模式，高效、专业'
      },
      {
        icon: '/images/content/key.svg',
        title: '千金一诺，不成功就退款',
        content: '网站提交认证审核后，认证项目审核失败不收取任何费用，100%全额退款'
      }
    ]
  }
]

export default {
  certificationalliance: {
    banner,
    cjnModules
  }
}