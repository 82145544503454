// 特性
const features = {
  title: 'Certhub 特点',
  options: [
    [
      { title: '即时证书发行', content: ['域名经过预先审核，可立即颁发 DigiCert/GlobalSign/Sectigo/BitCert 的所有SSL证书，以保护站点和服务器'], img: '' },
      { title: 'SSL产品和配置的种类', content: ['通过丰富的证书选项(包括一系列的保证级别和从非公开可信根发出的能力)来满足面向公众和内部服务器的需求'], img: '' }
    ],
    [
      { title: '完整的证书和用户管理', content: ['从一个集中的基于云的平台控制证书，包括生命周期管理，计费和用户权限'], img: '' },
      { title: '', content: [], img: '/images/certhub-check.svg' }
    ],
    [
      { title: '批量折扣和灵活条款', content: ['与购买个人证书相比有很大的折扣无需额外的托管或设置费用。灵活的业务条款支持各种规模的组织'], img: '' },
      { title: '证书监控', content: ['使用我们的证书库存管理工具查找和分析所有内部和公共证书报告即将到期，密钥长度，哈希算法，颁发CA等'], img: '' }
    ]
  ]
}

// 现代化证书管理
const manage = {
  title: '强大的SSL/TLS安全级别和配置选项范围',
  options: [
    {
      title: '发现并跟踪所有 SSL/TLS',
      summary: '跟踪 SSL/TLS 证书的有效性，配置何时通知的自定义阈值，提前很好地更新 SSL/TLS 证书并消除服务中断，合规性问题和中断。 SSL/TLS证书监控可为您提供帮助'
    },
    {
      title: '降低 SSL/TLS 的总拥有成本',
      summary: 'Certbase 的托管PKI 平台通过减少管理证书所需的工时、提供批量折扣和灵活的商业条款，大大降低了SSL的总体拥有成本'
    },
    {
      title: '定义并执行 SSL/TLS 策略',
      summary: '使用过时的加密技术或较弱的密钥大小可能会使您的公司容易受到攻击。确保所有SSL证书均符合企业政策， 并且只有适当的个人才能访问证书资源'
    }
  ]
}

// 底部 tabs
const tabs = [
  {
    index: 'discovery',
    label: '发现证书',
    panel: {
      coverImgUrl: '/images/certhub-check.svg',
      summary: '实时查找每一张 SSL/TLS 证书，使用我们先进的网络监控工具来深度扫描外部和内部环境。',
      contents: [
        '从一个位置查找，监视和分析所有内部和公共SSL证书，而不考虑颁发的CA是什么（包括自签名）',
        '通过电子邮件或者短信提醒来避免意外过期',
        '保持对密钥长度、哈希算法和其他证书数据的可见性的做法和行业合规性'
      ],
      link: '',
      linkText: '发现证书'
    }
  },
  {
    index: 'inventory',
    label: '证书库存管理',
    panel: {
      coverImgUrl: '/images/certhub-check.svg',
      summary: '所有SSL/TLS证书的360度透明无死角。无论证书颁发机构如何，都可以获取跨网络使用的证书的单一视图。',
      contents: [
        'Certbase 的证书工具(Certhub)可以查找您所有的SSL证书，并通过手动监控节省了宝贵的时间和资源。'
      ],
      link: '',
      linkText: '证书库存管理'
    }
  },
  {
    index: 'analyse',
    label: '证书分析',
    panel: {
      coverImgUrl: '/images/certhub-check.svg',
      summary: '了解网络中的每个弱证书或过期证书。获取内部信息，在安全威胁发生之前将其发现并处理。',
      contents: [
        '无论您的证书签发机构是哪个，都可以从每张数字证书的证书详情了解证书更详细的运行情况.',
         '通过分析证书品牌、密钥长度、哈希算法、证书有效期追踪等，全方位多维度的管理您部署的证书。'
      ],
      link: '',
      linkText: '证书分析'
    }
  },
  {
    index: 'audit',
    label: '证书审计',
    panel: {
      coverImgUrl: '/images/certhub-check.svg',
      summary: '证书审计简介',
      contents: [
        '通过对 HTTPS 网站的扫描，对 SSL 协议上爆出的相关漏洞进行监控和统计，助力站点安全性，防患于未然。'
      ],
      link: '',
      linkText: '证书审计'
    }
  }
]

export default {
  banner: {
    title: '全面的SSL/TLS证书管理',
    summary: '跨动态服务器目录发现、跟踪、报告和管理所有SSL/TLS证书，全面的SSL管理意味着发现、盘点和管理整个网络中的所有SSL证书和云服务。Certbase 提供集中的证书管理以及所有工具、服务和SSL/TLS产品，以降低风险，应对威胁并且控制成本。',
    link: 'https://app.certbase.com/register',
    linkText: '立即体验',
    bgUrl: '/images/certhub/certhub_manage.svg'
  },
  manage,
  features,
  tabs,
}
