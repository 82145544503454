export default {
  "privateInfo": {
    "tel": "400-808-5893"
  },
  "btn": {
    "bind": "绑定",
    "cancel": "取消",
    "confirm": "确定",
    "save": "保存",
    "update": "修改",
    "reUpdate": "修改",
    "next": "下一步",
    "off": "关闭",
    "on": "开启",
    "create": "创建",
    "decode": "解析"
  },
  "common": {
    "add": "添加",
    "brand": "品牌",
    "action": "操作",
    "balance": "余额",
    "buy": "购买",
    "bind": "绑定",
    "update": "修改",
    "buyNow": "立即购买",
    "currency": "人民币",
    "currencySymbol": "￥",
    "country": "国家",
    "commonName": "常用名称",
    "day": "天",
    "domain": "域名",
    "edit": "编辑",
    "year": "年",
    "price": "价格",
    "originalPrice": "市场价",
    "minute": "分钟",
    "message": "短信",
    "privateKey": "私钥",
    "productName": "产品名称",
    "trust": "信任等级",
    "issueTime": "签发时间",
    "validity": "有效期",
    "secures": "保护",
    "publicKeyAlgorithm": "加密算法",
    "symmetricEncryption": "加密位数",
    "publicKeyLength": "公钥长度",
    "rootCa": "根证书",
    "refundSince": "退款期限",
    "reIssue": "证书重签",
    "supportMulti": "支持多域名",
    "warranty": "保修金额",
    "login": "登录",
    "register": "注册",
    "compatible": "浏览器兼容",
    "submit": "提交",
    "danger": "不安全",
    "discount": "折扣",
    "privatekeyontoken": "硬件证书",
    "addDomains": "增加域名",
    "dv": "域名验证",
    "ov": "企业验证",
    "ev": "扩展验证",
    "term": "《服务条款》",
    "privacy": "《隐私政策》",
    "member": "《注册协议》",
    "refund": "《退款政策》",
    "address": {
      "label": "地址",
      "place": "请填写地址"
    },
    "password": {
      "label": "密码",
      "place": "请填写密码"
    },
    "newPassword": {
      "label": "新密码",
      "place": "请填写新密码"
    },
    "rePassword": {
      "label": "确认密码",
      "place": "请确认新密码"
    },
    "tel": {
      "label": "电话",
      "place": "请填写电话"
    },
    "phone": {
      "label": "手机",
      "place": "请填写手机"
    },
    "email": {
      "label": "邮箱",
      "place": "请填写邮箱"
    },
    "provice": {
      "label": "省份",
      "place": "请填写省份"
    },
    "city": {
      "label": "城市",
      "place": "请填写城市"
    },
    "company": {
      "label": "公司名称",
      "place": "请填写公司名称"
    },
    "name": {
      "label": "姓名",
      "place": "请填写姓名"
    },
    "position": {
      "label": "职位",
      "place": "请填写职位"
    },
    "validCode": {
      "label": "验证码",
      "place": "请填写验证码"
    },
    "postalCode": {
      "label": "邮编",
      "place": "请填写邮编"
    },
    "creditCode": {
      "label": "信用代码",
      "place": "请填写信用代码"
    }
  },
  "productType": {
    "email-signing": "邮件签名证书",
    "code-signing": "微软代码签名证书",
    "ev-code-signing": "微软 EV 代码签名证书",
    "document-signing": "文件签名证书",
    "single-domain": "单域名证书",
    "multi-domain": "多域名证书",
    "wildcard": "通配符证书",
    "domain-validation": "域名级证书",
    "organization-validation": "企业级证书",
    "extended-validation": "增强级证书",
    "other": "其他数字证书",
    "ssl": "SSL 证书",
    "doc": "文件签名",
    "code": "代码签名",
    "email": "邮件签名"
  },
  "domainType": {
    "single": "单域名",
    "wild": "通配符",
    "multi": "多域名",
    "flex": "域名级"
  },
  "validType": {
    "dv": "域名级SSL 证书",
    "ov": "企业级SSL 证书",
    "ev": "增强型SSL 证书"
  },
  "submitCsr": {
    "online": "在线生成",
    "brower": "自动生成 CSR",
    "paste": "粘贴 CSR"
  },
  "dcvMethod": {
    "dns": "DNS 解析",
    "file": "文件验证",
    "email": "邮件验证"
  }
}
