// 普通消费者
// https://certbase.com/partner/consumer

export default {
  pageTitle: '会员折扣计划',
  title: '会员折扣计划',
  subtitle: '所有客户都应该有资格享受折扣带来的喜悦',
  contents: [
    {
      title: '',
      content: [
        '您是电子商务建站公司、互联网服务商、服务器提供商、域名注册商或者专业从事数字证书销售的服务商家吗？您是否为您的客户提供IT服务或安全服务？我们是贵公司稳定的合作伙伴。当您购买多张证书时，我们会自动给您购买的产品打折扣，无需办理任何手续。您甚至都不用问我们就可以享受大量的折扣。',
        'Certbase 是全球 SSL/TLS 证书的大型发行商之一，隶属于成都汇智云安信息科技有限公司。我们的使命是建立信任的在线网络，因为我们充分认识到：只有建立一个可信的互联网，才能发挥其巨大潜能为人类沟通和商业活动造福，Certbase 在全力以赴的去帮助个人、网商、中小企业和大企业建立在线信任。目前，我们提供域名级（DV），企业级（OV）和增强级（EV）SSL/TLS 证书，通配符（Wildcard）、多域名（SAN）、代码签名（Code Signing）、文件签名（Document Signing）、IP 地址证书和S/MIME邮件签名证书。我们是Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。'
      ]
    },
    {
      title: '如何成为合作伙伴',
      content: [
        '在 Certbase 上订购任意数字证书的任何人都是我们的会员。会员折扣金额是根据会员帐户中有效（未过期）数字证书的总金额计算的。',
        '想咨询业务或者了解更多会员折扣计划信息，请联系 sales@certbase.com。'
      ]
    }
  ],
  list: {
    title: '会员折扣计划基本信息',
    content: [
      '自动获得高达 20% 的折扣',
      '起始折扣率在 5-10%',
      '如果您购买更多证书，还可以享受更高的折扣',
      '只销售市场上广泛信任的 SSL/TLS 证书产品',
      '活跃的会员还有额外礼物或者礼品卡'
    ]
  }
}
