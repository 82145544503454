const banner = {
  title: 'SMIME 邮件加密证书',
  btn: '立即咨询',
  summary: 'S/MIME邮件证书可以对您的邮件和附件进行数字签名和加密，为您的电子通信提供了高级别的机密性和安全性。 加密意味着只有您的预期收件人才能够阅读邮件，而数字签名允许他们确认您是发件人，并验证邮件是否在途中被篡改。 同时企业S/MIME邮件证书会审核证书持有人的企业身份信息，可有效防止钓鱼邮件。'
}
const cjnModules = [
  {
    title: 'SMIME 证书的好处',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '可扩展的安全性与合规性',
        content: '提供可扩展的企业级电子邮件安全保障，并遵守与医疗、政府、财务等相关的隐私和安全法规。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '任何平台，任何规模',
        content: '无论用户数量如何，都可以在几分钟内将 S/MIME 证书部署到使用任何操作系统的员工桌面和设备。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '高效自动化',
        content: '自动化 S/MIME 证书部署、配置一劳永逸的续订以及具有完整私钥历史记录和托管功能的自动备份/恢复。'
      }
    ]
  },
  {
    title: '核心优势',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '受信全球',
        content: '邮件证书（S/MIME）由全球信任的 CA 根证书签发，兼容性高，方案灵活，能满足各种复杂的应用场景需求。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '即时签发',
        content: '可以实现即时签发，企业型的邮件证书（S/MIME）在企业验证完成之后，申请的证书都能在短时间内签发。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '安全可靠',
        content: '邮件证书（S/MIME）可以防止邮件内容遭到篡改与内容泄露，使邮件通信更加安全高效并确保信息来源安全可靠。'
      }
    ]
  },
  {
    title: '功能介绍',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/ia_100000116.svg',
        title: '全自动一次性部署',
        content: '集中式和分散式一次性部署使网络团队能够代表用户进行部署，或启用自助服务用户模型。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '自动化密钥历史记录备份、恢复和密钥托管',
        content: '通过由组织的 IT 管理员管理的单个请求确保数据解密。无论是忘记密码、私钥被毁还是正常的续订过程，您都可以随时恢复完整的密钥历史记录。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '机构验证',
        content: '企业版安全电子邮件证书对机构名称、个人和电子邮件地址进行确认，以便将您的电子邮件与垃圾邮件或网络钓鱼攻击区分开来。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '证书吊销',
        content: '员工离职时立即吊销其数字身份证，以确保他们无法再以员工身份进行电子签名。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '目录服务集成',
        content: '与活动目录、LDAP、G-Suite 目录和其他常用目录服务进行集成和同步。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '安全部署到台式电脑和个人设备',
        content: 'Certbase 为企业和员工提供所需的灵活性，支持跨多种设备，例如台式电脑、手机和平板电脑。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '在所有用户设备上无缝确保电子邮件安全',
        content: 'Certbase 为主要移动设备管理解决方案提供集成点，允许将密钥和证书交付给用户的移动设备。'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '从任何设备将加密的大型文件发送给多个收件人',
        content: '用户无需压缩文件或密码即可安全发送大型文档，以此简化用户体验。自动选择收件人的证书和内置文件压缩功能，使用户能够安全地共享大型文件并发送给任意数量的内部或外部收件人'
      },
      {
        icon: '/images/content/ia_100000116.svg',
        title: '实时报告和监控',
        content: '避免 S/MIME 证书过期。该解决方案监控和管理 S/MIME 证书，并在证书接近到期时提示用户续订。'
      }
    ]
  },
  {
    title: '应用场景',
    span: 12, // 不用编辑
    layout: 'full-bottom', // 不用编辑
    contents: [
      {
        icon: '/images/scene_1_1608452855.png',
        title: '保护邮件信息安全',
        content: '使用邮件证书（S/MIME），实现邮件信息加密，保护邮件内容及附件的安全性，避免邮件内容被窃取。'
      },
      {
        icon: '/images/scene_1_1608452855.png',
        title: '防止邮件遭到篡改',
        content: '使用邮件证书（S/MIME），实现邮件签名功能，避免邮件被篡改而导致的经济损失。'
      }
    ]
  },
]


export default {
  scalesmime: {
    banner,
    cjnModules
  }
}