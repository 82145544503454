const banner = {
  title: '公有云代理',
  btn: '立即咨询',
  summary: '云服务器能为不同用户提供Web服务，也是一种云计算服务，并且可根据实际需求进行调整计算能力的大小，简单的说，就是用户在云端电脑运行所需应用系统通过云服务器可以实现，不必为处理器、联网、操作系统、存储的等而烦恼，可自主选择配置，并能选择购买模式，计算资源完全由用户控制，并且云服务器的Web服务接口超级简单易用。'
}
const cjnModules = [
  {
    title: '我们的业务',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '云产品代购',
        content: '作为阿里云,腾讯云,华为云,UCloud,AWS重要的合作伙伴，我们提供优惠代购。帮助企业节省成本、创造价值'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      },
      {
        icon: '/images/content/key.svg',
        title: '数据访问',
        content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
      }
    ]
  },
  {
    title: '核心优势',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '一账号覆盖多场景身份管控',
        content: '一个账号打通企业内部办公系统，并建立员工账号全生命周期管理体系。支持部门、角色、人员维度授权及员工离职账号自动吊销回收，避免账号安全风险'
      },
      {
        icon: '/images/content/key.svg',
        title: '远程安全连接你的办公环境',
        content: '内置VPN准入与WiFi一键接入功能，设备通过设备可信认证后，无论身处何地，都可以一键接入企业办公内部网络环境，同时VPN网络支持多节点服务器切换，提升远程办公稳定性'
      },
      {
        icon: '/images/content/key.svg',
        title: '为企业智能办公效率赋能',
        content: '与传统终端管理产品不同的是，CorpLink更加注重对于企业员工办公效能的提升，通过智能云打印、网络诊断等能力，切实帮助IT管理者与员工高效便捷地处理常见且耗时的问题'
      },
      {
        icon: '/images/content/key.svg',
        title: '全终端零信任对抗威胁风险',
        content: '通过多因素认证方式帮助企业完成设备授权登记，自动建立企业终端设备可信库，基于实时动态监测能力，设置终端准入安全基线，确保入网设备符合安全合规要求'
      }
    ]
  },
  {
    title: '功能介绍',
    span: 6, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '终端安全管控',
        content: '对设备进行安全基线检测，保证入网设备安全可控'
      },
      {
        icon: '/images/content/key.svg',
        title: 'VPN（虚拟专用网络）',
        content: '随时随地，远程安全地连接到办公网络'
      },
      {
        icon: '/images/content/key.svg',
        title: 'Wi-Fi一键接入',
        content: '登录后自动分配对应Wi-Fi密码，实现企业用户一键接入'
      },
      {
        icon: '/images/content/key.svg',
        title: '单点登录',
        content: '使用一个账号即可打通企业内部应用系统'
      },
      {
        icon: '/images/content/key.svg',
        title: 'MFA多因素认证',
        content: '支持多种动态口令验证，避免密码泄露保证账号安全'
      },
      {
        icon: '/images/content/key.svg',
        title: '账号生命周期管理',
        content: '入职即授予权限，离职后账号自动吊销回收'
      },
      {
        icon: '/images/content/key.svg',
        title: 'IT办公助手',
        content: '智能网络诊断功能，自动化协助用户解决设备问题'
      },
      {
        icon: '/images/content/key.svg',
        title: '软件中心',
        content: '企业内部软件管控，解决自研软件与商业软件分发问题'
      }
    ]
  },
  {
    title: '应用场景',
    span: 12, // 不用编辑
    layout: 'full-bottom', // 不用编辑
    contents: [
      {
        icon: '/images/scene_1_1608452855.png',
        title: '安全',
        content: '具备OTP两步验证和设备实时动态认证等措施，保证员工不会因为密码的遗失泄漏导致账号被盗用，任何新设备登录都需要通过飞连的安全基线检测'
      },
      {
        icon: '/images/scene_1_1608452855.png',
        title: '效率',
        content: '统一账号体系，通过SSO认证登录的方式打通企业内部各类系统平台，严格把控内部应用权限的可靠性，对于内部员工入、离职以及调岗等场景做了充分的权限管控措施'
      }
    ]
  },
]

const articles = [
  {
    icon: '/logo/globalsign.svg',
    layout: 'start',
    title: '数据访问',
    content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
  },
  {
    icon: '/logo/globalsign.svg',
    layout: 'end',
    title: '数据访问',
    content: '对象存储通过业界标准S3访问协议，支持对文件进行上传/下载/查看等操作访问'
  }
]

export default {
  publicCloud: {
    banner,
    articles,
    cjnModules
  }
}