import reseller from './reseller_en'
import member from './member_en'
import enterprise from './enterprise_en'
import affiliate from './affiliate_en'

export default {
  partner: {
    member,
    reseller,
    affiliate,
    enterprise,
    // 合作伙伴导航栏信息
    navbar: {
      title: 'Certbase 合作伙伴体系',
      options: [
        { label: 'Enterprise', link: '/partner/enterprise' },
        { label: 'Reseller', link: '/partner/reseller' },
        { label: 'Affiliate', link: '/partner/affiliate' },
        { label: 'Member', link: '/partner/member' }
      ]
    },
    contact: {
      title: 'CONTACT OUR SUPPORT TEAM',
      link: '',
      linkText: 'Contact us',
      options: [
        { label: 'Telephone', link: 'tel:400-808-5893', value: '400-808-5893' },
        { label: 'Phone', link: 'tel:177-0811-9188', value: '177-0811-9188' },
        { label: 'E-mail', link: 'mailto:support@certbase.com', value: 'support@certbase.com' },
      ]
    }
  }
}
