/**
 * 发现证书 英文版
 * 页面路由 https://certbase.com/en/certhub/discovery
 */

const banner = {
  title: '发现证书',
  summary: '实时查找每一张SSL/TLS证书，使用我们先进的网络监控工具来深度扫描外部和内部环境。',
  link: '',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_dicovery.svg'
}

// 现代化证书管理
const manage = {
  title: '发现证书使您的工作流程自动化',
  options: [
    {
      title: '扫描网络端口',
      summary: '监视公共网站和内网端点。'
    },
    {
      title: '子域名查找',
      summary: '自动查找并扫描所有子域名。'
    },
    {
      title: '持续定期监控',
      summary: '设置每月，每周或每天的网络扫描。'
    }
  ]
}

// 特性
const features = {
  title: '发现证书的特点',
  options: [
    [
      { title: '公网监控', content: ['按单个域名，域列表或IP范围扫描公共可用端点。'], img: '' },
      { title: '证书透明度日志监控', content: ['如果检测到具有所需域名或子域名的证书，则会将其添加到证书列表中。 在一分钟内发送短信或者邮件警报。'], img: '' }
    ],
    [
      { title: '内网监测', content: ['内部网络发现用于可以通过内部IP范围在本地网络上访问的任何本地服务器上。', '只需双击即可安装并运行Windows或Linux代理程序，用来扫描内部环境。'], img: '' },
      { title: '', content: [], img: '/images/certhub-check.svg' }
    ],
    [
      { title: '灵活的扫描设置', content: ['根据需要创建任意数量的扫描配置文件，一个配置文件中可包含10,000个目标任务。'], img: '' },
      { title: '实时状态发现', content: ['直接从仪表板或在扫描配置文件页面上跟踪正在进行的扫描进度。'], img: '' }
    ]
  ]
}

// 步骤
const step = {
  title: '3步快速发现证书',
  link: '', // 按钮点击前往地址
  linkText: '立即体验',
  options: [
    {
      img: '/images/certhub/create_task.png',
      summary: '创建发现证书扫描任务'
    },
    {
      img: '/images/certhub/input_domain.png',
      summary: '设置目标域名、IP地址。'
    },
    {
      img: '/images/certhub/select_frequency.png',
      summary: '安排定期自动扫描。'
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}
