export default {
  pageTitle: '大客户计划',
  title: 'Certbase 大客户计划',
  subtitle: 'Certbase 能够帮助减少与管理多个企业级 SSL 证书相关的时间、工作量和控制成本',
  contents: [
    { // 1
      title: '',
      content: [
        'Certbase 是全球 SSL/TLS 证书的大型发行商之一，隶属于成都汇智云安信息科技有限公司。我们的使命是建立信任的在线网络，因为我们充分认识到：只有建立一个可信的互联网，才能发挥其巨大潜能为人类沟通和商业活动造福，Certbase 在全力以赴的去帮助个人、网商、中小企业和大企业建立在线信任。目前，我们提供域名级（DV），企业级（OV）和增强级（EV）SSL/TLS 证书，通配符（Wildcard）、多域名（SAN）、代码签名（Code Signing）、文件签名（Document Signing）、IP 地址证书和S/MIME邮件签名证书。我们是Sectigo、GlobalSign、DigiCert、Entrust 的授权合作伙伴。我们拥有大量产品，并从供应商处获得大量折扣，这使我们能够将折扣传递给您并提供低的价格。我们在超过10,000个客户中销售了超过100,000个SSL证书，在全球拥有几十家经销商。'
      ]
    },
    { // 2
      title: 'Certbase 可以简化数字证书的管理',
      content: [
        '数字证书中断，即组织忘记替换业务关键型域名的到期证书的情况，不断造成业务中断，对许多组织而言这是一种安全风险。数字证书过期后您的网站将被禁用。在大多数情况下，这是由于选择劣质注册商或不了解域名在组织架构中的关键作用所致。',
        '拥有多个网站、多台设备、多个网络应用程序和多位员工的企业，则需要被妥善管理的大量数字证书。追踪记录数十、数百或可能数千份证书对任何组织的任何管理员都颇具挑战性。',
        '这种挑战加重了证书管理方面本已繁重的工作量，所以 CAB/F（证书颁发机构和浏览器论坛）已决定使公司可注册证书的有效期缩短至约一年（398 天），此前则为两年。根据 Ponemon Institute 在 2019 年 1 月发布的研究报告《不安全数字身份的影响》：',
        '总体来说，73% 的受访者表示其组织经历过因数字证书管理不当导致的意外停机或中断，55% 则表示过去两年内发生过四次或以上的证书相关中断。',
        '大多数组织也可能在为管理哪些要部署的密钥和证书而疲于奔命（包括已自行签名的证书），其中有 74% 的受访者暗示他们的企业不知道哪些密钥和证书在使用、在哪里找到这些密钥和证书，以及这些密钥和证书何时过期。'
      ]
    },
    { // 4
      title: 'Certbase 提供全方位 SSL/TLS 证书管理',
      content: [
        'Certbase 证书清单工具（Certhub）定位您的所有SSL证书，并且比手动监控节省宝贵的时间和资源。',
      ]
    }
  ],

  lists: [
    {
      // 列表 3
      title: 'Certbase 可以降低SSL的总体拥有成本',
      content: [
        '相比购买个人证书可以有更大量的折扣',
        '灵活的业务条款适应各种规模的组织。 选择在你去支付时，或存款帐户资金，消除需要购买和跟踪令牌或“包”',
        '灵活的证书许可选项，使所有的网站可以固定年度付款，安全便捷。',
        '通过SAN许可选项容纳变更的服务器库存，允许在证书库存中有指定数量的活动唯一SAN，从而使客户能够提供试用或短期证书，而不会影响底线',
        '商家可以使用 Certbase 提供的各种公共信任的数字证书来扩展其产品线。'
      ],
    },
    {
      // 列表 5
      title: '',
      content: [
        '无论发行CA（包括自签名），都可以从一个位置查找，监控和分析所有内部和公共SSL证书',
        '使用短信或者电子邮件提醒续订，避免意外到期',
        '快速响应全球性威胁，如Heartbleed',
        '了解实践和行业合规性，了解密钥长度，散列算法和其他证书数据',
        '管理并预加载您的帐户资金。',
        '集中并监控一个帐户下的多个业务实体，部门和角色的证书活动。',
        '检测和修复不符合证书配置的企业策略的证书',
        '控制哪些人可以颁发证书，哪些类型的证书可以颁发，以及可以发布到哪些域名。',
        '强大的API集成，可将证书有效期自动化到现有工作流程中'
      ]
    }
  ]
}
