export default {
  "apply": {
    "pageTitle": "购买证书 - Certbase®",
    "btn": "立即申请",
    "tips": "SSL/TLS 证书的有效期为13个月（398天）； 购买多年计划证书需要在订单有效期内 SSL/TLS 证书到期前申请重新颁发证书。",
    "mode": {
      "label": "下单模式",
      "value": "批量下单"
    },
    "productType": {
      "label": "产品类型",
      "ssl": "SSL 证书",
      "doc": "文件签名",
      "code": "代码签名",
      "email": "邮件签名",
      "ip": "公网 IP"
    },
    "year": {
      "label": "购买年限",
      "day": "天证书"
    },
    "validType": {
      "label": "验证类型",
      "dv": "DV 域名级",
      "ov": "OV 企业级(推荐)",
      "ev": "EV 增强级"
    },
    "brand": {
      "label": "品牌名称"
    },
    "dcvMethod": {
      "label": "验证方式",
      "value": [
        "文件验证",
        "DNS 解析"
      ]
    },
    "domainType": {
      "label": "域名类型",
      "value": "我想要通配符/泛域名证书（*.certbase.com）"
    },
    "batchDomainIput": {
      "label": "多域名",
      "value": "我想添加附加域名"
    },
    "submitType": {
      "label": "CSR提交",
      "value": [
        "自动生成 CSR",
        "我想粘贴自己的CSR"
      ]
    },
    "encryption": {
      "label": "加密算法"
    },
    "signature": {
      "label": "签名算法"
    },
    "privateKeyLen": {
      "label": "私钥长度"
    },
    "curve": {
      "label": "秘钥曲线"
    },
    "org": {
      "label": "组织名称",
      "place": "请填写组织名称"
    },
    "unit": {
      "label": "证书部门",
      "place": "请填写证书部门（选填）"
    },
    "domain": {
      "label": "常用名称",
      "btn": [
        "增加域名",
        "取消"
      ],
      "place": {
        "domain": "请输入域名",
        "wild": "请输入通配符域名",
        "ip": "请输入域名或公网 IP 地址"
      },
      "append": "不同域名或公网 IP 地址之间请使用换行符隔开，我们支持以下格式：\ncertbase.com"
    },
    "email": {
      "label": "填写邮箱",
      "place": "请输入邮箱"
    },
    "batch": {
      "domain": {
        "label": "填写域名",
        "single": "不同订单使用英文分号(;)隔开。\n例如：example1.com;example2.com;",
        "flex": "通用名称之间使用英文逗号(,)隔开，取第一个作为主域名；不同订单使用英文分号(;)隔开。注意：通配符不能为第一个。\n例如：example.com,*.example.com,129.204.7.163;",
        "wild": "不同订单使用英文分号(;)隔开。\n例如：*.example1.com;*.example2.com;",
        "ip": "请填写IP地址，多个IP之间用英文逗号(,)隔开。\n例如：129.204.7.163,129.204.7.162;129.204.7.161,129.204.7.160;",
        "tips": [
          "域名",
          "格式不正确"
        ]
      },
      "email": {
        "place": "邮箱之间使用英文（,）逗号隔开",
        "tips": "格式错误！"
      }
    },
    "edit": {
      "title": "证书基本信息"
    },
    "contact": {
      "title": [
        "联系人信息",
        "公司 / 联系人信息"
      ],
      "person": {
        "label": "联系人",
        "place": [
          "可通过联系人的电话查询",
          "请添加联系人"
        ]
      },
      "company": {
        "label": "公司",
        "place": "请添加公司/组织信息"
      }
    },
    "viewer": {
      "title": [
        "申请信息",
        "订单联系人信息",
        "公司信息"
      ],
      "ip": "IP 地址",
      "count": "总计费用",
      "origin": "原始价格",
      "validType": {
        "dv": "域名级SSL 证书",
        "ov": "企业级SSL 证书",
        "ev": "增强型SSL 证书"
      }
    }
  }
}
