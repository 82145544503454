/**
 * 证书审计 中文版
 * 页面路由 https://certbase.com/certhub/audit
 */

 // Banner
const banner = {
  title: 'SSL/TLS 证书审计与评估报告',
  summary: '此工具深入扫描TLS（也就是HTTPS）的整体配置和运行状况。通过简单的扫描，您可以立即知道TLS在部署实施中是否存在深层次的问题，是否容易受到某些令人讨厌的漏洞（例如heartbleed，ROBOT）或者正在使用的过时加密算法等等的影响。',
  link: 'https://app.certbase.com/register',
  linkText: '立即体验',
  bgUrl: '/images/certhub/certhub_audit.svg'
}

// 证书审计说明
const manage = {
  title: '证书审计使您的工作流程自动化',
  options: [
    {
      title: 'TLS 协议检测',
      summary: '获取HTTPS网站支持的SSL/TLS协议，为您优化TLS协议进行协助。'
    },
    {
      title: 'TLS 加密套件检测',
      summary: '获取HTTPS网站支持的加密套件，凸现不安全的加密套件。'
    },
    {
      title: 'TLS 漏洞检测',
      summary: '可帮助您快速发现网站中容易被利用的漏洞并采取相应的防御措施。'
    }
  ]
}

// 特性
const features = {
  title: '证书审计特性',
  options: [
    [
      // 若是图片填充填写图片URL 若不是保留空值
      { title: '公网站点审计', content: ['自动检测HTTPS站点安全配置，识别内网加密协议、套件以及关键漏洞。'], img: '' }, // 左
      { title: '内网端点审计', content: ['通过使用 Certbase 封装的检测工具，可以快速检测内网端点网站安全配置，识别内网加密协议、套件以及关键漏洞'], img: '' } // 右
    ],
    [
      { title: '公网 IP 站点审计', content: ['自动检测使用HTTPS的IP地址网站安全配置，识别网站加密协议以及套件，提示关键漏洞'], img: '' },
      { title: '', content: [], img: '' }
    ]
  ]
}

// 步骤
const step = {
  title: '3步快速检测',
  link: 'https://app.certbase.com/register', // 按钮点击前往地址
  linkText: '立即体验',
  options: [
    {
      img: '/images/certhub/audit_input.png',
      summary: '输入您需要检测的网站地址'
    },
    {
      img: '/images/certhub/audit_doing.png',
      summary: '等待检测报告生成'
    },
    {
      img: '/images/certhub/audit_done.png',
      summary: '查看报告并根据该报告进行安全配置优化'
    }
  ]
}

export default {
  banner,
  manage,
  features,
  step
}
