const banner = {
  title: 'IDC 服务',
  btn: '立即咨询',
  summary: '汇智云安联合第三方IDC服务商可代管用户自有或租用的国内网络、网络元素或设备，包括为用户设备放置提供网络管理、机柜出租、U位出租、综合布线服务、物理空间定制化交付等业务服务。'
}
const cjnModules = [
  {
    title: '产品服务',
    span: 12, // 不用编辑
    layout: 'horizontal', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: 'IDC基础服务',
        content: 'IDC机柜租赁， 白机房租赁， 裸金属服务器， 办公场地服务， VIP机房服务， 数据中心光纤出租， 骨干网带宽接入， BGP带宽接入， IP地址出租， 综合布线服务， 设备代维服务， 全天候巡检服务。'
      },
      {
        icon: '/images/content/key.svg',
        title: 'IDC ITO服务',
        content: '服务器托管， 主机托管， 网站备案， 系统监控服务， 数据存储备份， IDC迁移服务， 均衡负载服务， IDC灾备服务， 软件技术服务， 测试平台服务， 信息系统运维服务， 企业应用服务， 桌面管理代维服务， 网络管理代维服务。'
      },
      {
        icon: '/images/content/key.svg',
        title: 'IDC安全服务',
        content: '安全运维服务， Web安全扫描， 等保解决方案， 安全增值产品， 云联网， 安全加固， CA认证解决方案， 云化WAF， 安全审计服务， 病毒防御服务， 流量清洗服务。'
      },
      {
        icon: '/images/content/key.svg',
        title: 'IDC建设及咨询服务',
        content: 'IDC物理空间定制建设服务， IDC链路定制化建设服务， IDC机房基础设施改造服务， IDC建设方案咨询服务， IDC机房认证咨询服务， IDC资源配置咨询服务。'
      }
    ]
  },
  {
    title: '数据中心优势',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '数据中心',
        content: '底层融合服务器安全防护硬件设备，让物理主机实体能够有效防御黑客的攻击，保障服务器安全。'
      },
      {
        icon: '/images/content/key.svg',
        title: '双路供电',
        content: 'IDC内有双路供电油机备份的电力保障、尊贵的VIP机房包间、24小时的技术支持和售后服务。'
      },
      {
        icon: '/images/content/key.svg',
        title: 'G级接入',
        content: '友商为西南地区规模大的IDC服务商之一，拥有500G超大带宽，直接接入ChinaNet骨干网。'
      },
      {
        icon: '/images/content/key.svg',
        title: '多路加速',
        content: '动静态加速，下载加速，流媒体加速覆盖全国网络节点资源，灵活配置，防盗链高可用。'
      },
      {
        icon: '/images/content/key.svg',
        title: '快速上线',
        content: '工程师提供 7*24 小时快速响应服务，全面实施服务器即售即上架的服务标准。'
      },
      {
        icon: '/images/content/key.svg',
        title: '配置灵活',
        content: '专门为特殊应用场景打造的专属服务器配置，优质资源并有合适的价格，兼得鱼与熊掌。'
      },
    ]
  },
  {
    title: '服务器机柜租用优势',
    span: 8, // 不用编辑
    layout: 'vertical', // 不用编辑
    contents: [
      {
        icon: '/images/content/key.svg',
        title: '数据中心',
        content: '双线，电信，联通等国内多个五星级数据中心任选，品牌服务器，快速开通'
      },
      {
        icon: '/images/content/key.svg',
        title: '网络品质',
        content: '双线数据中心采用双核心，全冗余双星型架构，多链路备份，智能网络监控，故障实时切换'
      },
      {
        icon: '/images/content/key.svg',
        title: '自助维护',
        content: '开通PM远程自助管理/维护功能，24小时随时重启/重装服务器系统，省时高效，方便快捷'
      },
      {
        icon: '/images/content/key.svg',
        title: '技术服务',
        content: '超过15名技术或研发人员为技术服务保障，从软硬件全方位护航您的互联'
      },
      {
        icon: '/images/content/key.svg',
        title: '电力保障',
        content: '独立双路市电，充沛电力持续供应保证，UPS搭载智能燃气发电系统'
      },
      {
        icon: '/images/content/key.svg',
        title: '温度保障',
        content: 'N+1上抽风下送风设计，冷热通道分离配备冷池技术用于高密度机柜制冷'
      },
    ]
  }
]

export default {
  idc: {
    banner,
    cjnModules
  }
}